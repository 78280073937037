<template>
    <headers></headers>
    <div class="top-gap"></div>
    <div class="stack">
        <div>
            <div class="title">
                <b>SOLFI Token</b>
            </div>
            <p>{{ $t('top.tokenDesc') }}</p>
            <img src="../../static/images/award-icon.png" >
        </div>
    </div>
    <div class="buy-lay">
        <div>
            <div class="top">
                <div class="left-lay">
                    <div class="title">SOLFI</div>
                    <div class="price">
                        <div>
                            {{ $t('top.price') }}
                            <div> {{Util.showAmount(topInfo.price)}} USDT</div>
                        </div>
                        <div class="amount">
                            <div>
                                <div>{{ $t('top.allAmount') }}</div>
                                <div class="num">{{Util.showAmount(topInfo.totalSupply)}} SOLFI</div>
                            </div>
                            <div>
                                <div>{{ $t('top.market') }}</div>
                                <div class="num">{{Util.showAmount(topInfo.price * topInfo.totalSupply)}} USDT</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-lay">
                    <div class="title">{{ $t('top.my') }}</div>
                    <div class="price">
                        {{ $t('top.earn') }}
                            <div> {{Util.showAmount(solfiInfo.interestTotal)}} SOLFI</div>
                    </div>
                    <div class="get-lay">
                        <div class="price">
                            {{ $t('top.getYet') }}
                                <div> {{Util.showAmount(solfiInfo.interestRemain)}} SOLFI</div>
                        </div>
                        <div class="get-active pc" v-if="solfiInfo.interestRemain > 0" @click="withdrawSOLFIInterest">{{ $t('top.get') }}</div>
                        <div class="get-disabled pc" v-else>{{ $t('top.get') }}</div>
                    </div>
                    <div class="mobile mobile-get">
                        <div class="active" v-if="solfiInfo.interestRemain > 0" @click="withdrawSOLFIInterest">{{ $t('top.get') }}</div>
                        <div class="disabled" v-else>{{ $t('top.get') }}</div>
                    </div>
                </div>
            </div>
            <!-- 认购 -->
            <div class="buy" v-if="swapSwitch === 0">
                <div class="title">{{ $t('top.buy') }}</div>
                <div class="row">
                    <input placeholder="0.0000" v-model="inputUSDTAmount" @keyup="usdtCountTop"/>
                    <div class="icon">
                        <div>
                            <img src="../../static/images/sol.png" />
                            SOL
                        </div>
                        <div>{{ $t('top.balance') }}: {{Util.showAmount(wallet.usdtBalance)}}</div>
                    </div>
                </div>
                <div class="down-icon" @click="swapSwitch=1">
                    <img src="../../static/images/down.png" />
                </div>
                <div class="row row-top">
                    <input placeholder="0.0000"  v-model="inputTopAmount" @keyup="topCountUSDT"/>
                    <div class="icon">
                        <div>
                            <img src="../../static/images/logo-notext.png" />
                            SOLFI
                        </div>
                        <div>{{ $t('top.balance') }}: {{Util.showAmount(wallet.topBalance)}}</div>
                    </div>
                </div>
                <button @click="buy" v-if="!wStore.curAccount || (topInfo.buyState && inputUSDTAmount - 0 > 0 && inputTopAmount - 0 > 0 && wallet.usdtAllowance - inputUSDTAmount >= 0 && wallet.usdtBalance - inputUSDTAmount >= 0)">{{ $t('top.buy') }}</button>
                <button @click="approveUSDT" v-else-if="wStore.curAccount && wallet.usdtAllowance - inputUSDTAmount < 0">{{ $t('top.approve') }}</button>
                <button class="disabled" v-else>{{ $t('top.buy') }}</button>
            </div>

            <!-- 卖出 -->
            <div class="buy" v-else>
              <div class="title">{{ $t('top.buy') }}</div>
              <div class="row row-top">
                <input placeholder="0.0000"  v-model="inputTopAmount" @keyup="topCountUSDT"/>
                <div class="icon">
                  <div>
                    <img src="../../static/images/logo-notext.png" />
                    SOLFI
                  </div>
                  <div>{{ $t('top.balance') }}: {{Util.showAmount(wallet.topBalance)}}</div>
                </div>
              </div>
              <div class="down-icon" @click="swapSwitch=0">
                <img src="../../static/images/down.png" />
              </div>
              <div class="row">
                <input placeholder="0.0000" v-model="inputUSDTAmount" @keyup="usdtCountTop"/>
                <div class="icon">
                  <div>
                    <img src="../../static/images/sol.png" />
                    SOL
                  </div>
                  <div>{{ $t('top.balance') }}: {{Util.showAmount(wallet.usdtBalance)}}</div>
                </div>
              </div>
              <button @click="sell" v-if="!wStore.curAccount || (topInfo.sellState && inputUSDTAmount - 0 > 0 && inputTopAmount - 0 > 0 && wallet.topAllowance - inputTopAmount >= 0 && wallet.topBalance - inputTopAmount >= 0)">{{ $t('top.buy') }}</button>
              <button @click="approveTOP" v-else-if="wStore.curAccount && wallet.topAllowance - inputTopAmount < 0">{{ $t('top.approveTop') }}</button>
              <button class="disabled" v-else>{{ $t('top.buy') }}</button>
            </div>
        </div>
    </div>
    <bases></bases>
</template>
<script setup>
    import headers from '@/components/header'
    import bases from '@/components/base'
    import {ref, reactive, computed, watch, onMounted} from 'vue';

    import {memberStore} from "@/store/member"
    import {walletStore} from "@/store/wallet"
    import {webStore} from "@/store/web"
    import i18n from "@/i18n";
    import {ElMessage} from "element-plus";
    import {useRouter} from "vue-router";
    import {call, getContractAddress, send} from "@/contract/web3-util";
    import Util from "@/utils/common-util";
    import Web3 from "web3";
    import {end, start} from "@/api/loading";

    const mStore = memberStore()
    const wStore = walletStore()
    const wbStore = webStore()

    const router = new useRouter()

    const topInfo = reactive({
      buyState: 0,
      sellState: 0,
      price: 0,
      totalSupply: 0
    })

    const wallet = reactive({
      usdtBalance: 0,
      usdtAllowance: 0,
      topBalance: 0,
      topAllowance: 0
    })

    const solfiInfo = reactive({
      interestTotal: 0,
      interestRemain: 0
    })

    const inputUSDTAmount = ref('')
    const inputTopAmount = ref('')

    const swapSwitch = ref(0)

    const goPage = (url) => {
        router.push({
            path: url
        })
    }

    async function getSOLFISwapInfo() {
      const info = await call('stake-v2', 'getSOLFISwapInfo')
      topInfo.buyState = info.buyState
      topInfo.sellState = info.sellState
      topInfo.price = Web3.utils.fromWei(info.price) - 0
      topInfo.totalSupply = Web3.utils.fromWei(info.totalSupply) - 0
    }

    const getWalletInfo = async() => {
      if (wStore.curAccount) {
        wallet.usdtBalance = Web3.utils.fromWei(await call('sol', 'balanceOf', [wStore.curAccount])) - 0
        wallet.usdtAllowance = Web3.utils.fromWei(await call('sol', 'allowance', [wStore.curAccount, getContractAddress('stake-v2')])) - 0
        wallet.topBalance = Web3.utils.fromWei(await call('solfi', 'balanceOf', [wStore.curAccount])) - 0
        wallet.topAllowance = Web3.utils.fromWei(await call('solfi', 'allowance', [wStore.curAccount, getContractAddress('stake-v2')])) - 0
      }
    }

    const getAccountSOLFIInfo = async() =>  {
      if (wStore.curAccount) {
        const info = await call('stake-v2', 'getSOLFIInterestInfo', [wStore.curAccount])
        solfiInfo.interestTotal = Web3.utils.fromWei(info.interestTotal) - 0
        solfiInfo.interestRemain = Web3.utils.fromWei(info.interestRemain) - 0
      }
    }

    function withdrawSOLFIInterest() {
      if (!wStore.curAccount) {
        ElMessage({
          message: i18n.global.t('alert.connectWallet'),
          type: 'warning'
        })
        return
      }

      start()
      send('stake-v2', 'withdrawSOLFIInterest')
          .then((receipt) => {
            if (receipt.status) {
              ElMessage({
                message: i18n.global.t('alert.success'),
                type: 'success'
              })

              getAccountSOLFIInfo()
            } else {
              ElMessage({
                message: i18n.global.t('alert.failed'),
                type: 'error'
              })
            }
            end()
          }).catch(function(error) {
        console.error(error)
        end()
      })
    }

    async function usdtCountTop() {
      if (/^((0\.0*[1-9]+[0-9]*)|([1-9]+[0-9]*\.[0-9]*[0-9])|([1-9]+[0-9]*))$/.test(inputUSDTAmount.value) && inputUSDTAmount.value - 0 > 0) {
        const amounts = await call('stake-v2', 'getSOLFISwapAmount', [Web3.utils.toWei(inputUSDTAmount.value + ""), 0])
        console.log(amounts)
        inputTopAmount.value = Web3.utils.fromWei(amounts.solfiA)
      } else {
        inputTopAmount.value = ''
      }
    }

    async function topCountUSDT() {
      if (/^((0\.0*[1-9]+[0-9]*)|([1-9]+[0-9]*\.[0-9]*[0-9])|([1-9]+[0-9]*))$/.test(inputTopAmount.value) && inputTopAmount.value - 0 > 0) {
        const amounts = await call('stake-v2', 'getSOLFISwapAmount', [0, Web3.utils.toWei(inputTopAmount.value + "")]);
        console.log(amounts)
        inputUSDTAmount.value = Web3.utils.fromWei(amounts.solA)
      } else {
        inputUSDTAmount.value = ''
      }
    }

    const approveUSDT = () => {
      if (!wStore.curAccount) {
        ElMessage({
          message: i18n.global.t('alert.connectWallet'),
          type: 'warning'
        })
        return
      }

      start()
      send('sol', 'approve', [getContractAddress('stake-v2'), '115792089237316195423570985008687907853269984665640564039457584007913129639935'])
          .then((receipt) => {
            if (receipt.status) {
              ElMessage({
                message: i18n.global.t('alert.success'),
                type: 'success'
              })

              wallet.usdtAllowance = Web3.utils.fromWei('115792089237316195423570985008687907853269984665640564039457584007913129639935', 'ether') - 0
            } else {
              ElMessage({
                message: i18n.global.t('alert.failed'),
                type: 'error'
              })
            }
            end()
          }).catch(function(error) {
        console.error(error)
        end()
      })
    }

    const approveTOP = () => {
      if (!wStore.curAccount) {
        ElMessage({
          message: i18n.global.t('alert.connectWallet'),
          type: 'warning'
        })
        return
      }

      start()
      send('solfi', 'approve', [getContractAddress('stake-v2'), '115792089237316195423570985008687907853269984665640564039457584007913129639935'])
          .then((receipt) => {
            if (receipt.status) {
              ElMessage({
                message: i18n.global.t('alert.success'),
                type: 'success'
              })

              wallet.topAllowance = Web3.utils.fromWei('115792089237316195423570985008687907853269984665640564039457584007913129639935', 'ether') - 0
            } else {
              ElMessage({
                message: i18n.global.t('alert.failed'),
                type: 'error'
              })
            }
            end()
          }).catch(function(error) {
        console.error(error)
        end()
      })
    }

    function buy() {
      if (wStore.curAccount) {
        if (!topInfo.buyState) {
          return
        }
        if (!/^((0\.0*[1-9]+[0-9]*)|([1-9]+[0-9]*\.[0-9]*[0-9])|([1-9]+[0-9]*))$/.test(inputUSDTAmount.value)) {
          ElMessage({
            message: i18n.global.t('alert.amountError'),
            type: 'warning'
          })

          return
        }

        if (inputUSDTAmount.value - 1 < 0) {
          ElMessage({
            message: i18n.global.t('alert.minAmountError') + ' 1 SOL',
            type: 'warning'
          })

          return
        }

        start()
        send('stake-v2', 'swapSOLFI', [0, Web3.utils.toWei(inputUSDTAmount.value), 0]).then(receipt => {
          end()
          if (receipt.status) {
            ElMessage({
              message: i18n.global.t('alert.success'),
              type: 'success'
            })

            inputUSDTAmount.value = ''
            inputTopAmount.value = ''
            getWalletInfo()
          } else {
            ElMessage({
              message: i18n.global.t('alert.failed'),
              type: 'error'
            })
          }
          end()
        }).catch(function(error) {
          console.error(error)
          ElMessage({
            message: i18n.global.t('alert.failed'),
            type: 'error'
          })
          end()
        })
      }
    }

    function sell() {
      if (wStore.curAccount) {
        if (!topInfo.sellState) {
          return
        }
        if (!/^((0\.0*[1-9]+[0-9]*)|([1-9]+[0-9]*\.[0-9]*[0-9])|([1-9]+[0-9]*))$/.test(inputTopAmount.value)) {
          ElMessage({
            message: i18n.global.t('alert.amountError'),
            type: 'warning'
          })

          return
        }

        if (inputTopAmount.value - 1 < 0) {
          ElMessage({
            message: i18n.global.t('alert.minAmountError') + ' 1 TOP',
            type: 'warning'
          })

          return
        }

        start()
        send('stake-v2', 'swapSOLFI', [1, 0, Web3.utils.toWei(inputTopAmount.value)]).then(receipt => {
          end()
          if (receipt.status) {
            ElMessage({
              message: i18n.global.t('alert.success'),
              type: 'success'
            })

            inputUSDTAmount.value = ''
            inputTopAmount.value = ''
            getWalletInfo()
          } else {
            ElMessage({
              message: i18n.global.t('alert.failed'),
              type: 'error'
            })
          }
          end()
        }).catch(function(error) {
          console.error(error)
          ElMessage({
            message: i18n.global.t('alert.failed'),
            type: 'error'
          })
          end()
        })
      }
    }

    onMounted(() => {
      getSOLFISwapInfo()
      getWalletInfo()
      getAccountSOLFIInfo()
    })

    const curAccount = computed(()=>{
      return wStore.curAccount
    })
    watch(curAccount, (newVal, oldVal) =>{
      if (newVal) {
        if (oldVal) {
          alert(i18n.global.t('alert.reLogin'))
        }

        wbStore.accountLogin().then((result) => {
          wbStore.setLoginState(true)
          mStore.queryMemberInfo()

          getWalletInfo()
          getAccountSOLFIInfo()

        }).catch((reason) => {
          wbStore.setLoginState(false)
          console.error('login failed')
        })
      }
    })
</script>
<style scoped src="./css/index.css">
</style>
